import React from "react";

const Columns = ({ item, ...props }) => {
  const columns = item.item.data;

  return (
    <div className="flex items-center h-full w-full text-lg relative justify-around">
      {columns.map((column, index) => (
        <div key={`${index}-${column.type}`} className="p-1 max-h-full max-w-max relative">
          {
            {
              text:
                column.content &&
                column.content.match(/(.*[^\n])/gm).map((line, index) => (
                  <p key={`${index}-${line}`} className="py-0.5 leading-5 text-lg text-left">
                    {line}
                  </p>
                )),
              image: <img alt="" className="object-contain max-h-48 max-w-xs" src={column.content} />,
            }[column.type]
          }
        </div>
      ))}
    </div>
  );
};
export default Columns;
