import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const PdfNotice = ({
  notice,
  noticeDisplayTime,
  containerHeight,
  containerWidth,
  ...props
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [PDF, setPDF] = useState(null);
  const [pageWidth, setPageWidth] = useState(100);
  const [pageHeight, setPageHeight] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [maxScroll, setMaxScroll] = useState(0);
  const [documentLoaded, setDocumentLoaded] = useState(false);

  const containerRef = useRef(null);
  const scrollInterval = useRef(null);
  const timeoutRef = useRef(null);

  const scale = useMemo(() => {
    if (pageHeight === 0 || pageWidth === 0) return 1;

    const calculatedScale = Math.min(
      containerHeight / pageHeight,
      containerWidth / pageWidth
    );

    const enableScrolling = notice?.data?.options?.scrolling === 'true';
    return enableScrolling ? calculatedScale * 1.2 : calculatedScale;
  }, [pageHeight, pageWidth, containerHeight, containerWidth, notice?.data]);

  const calculateMaxScroll = () => {
    if (!containerRef.current) return 0;

    const { scrollHeight, clientHeight } = containerRef.current;
    const calculatedMaxScroll = scrollHeight - clientHeight;

    setMaxScroll(calculatedMaxScroll > 0 ? calculatedMaxScroll : 0);
  };

  const startTimeout = () => {
    // Start the timeout only if all necessary states are valid
    if (!numPages || !pageLoaded || !documentLoaded || numPages < 1) return;

    const timeoutPerPage = noticeDisplayTime / numPages;

    timeoutRef.current = setTimeout(() => {
      setPageNumber((prevPage) => {
        const nextPage = prevPage + 1;
        return nextPage > numPages ? 1 : nextPage; // Loop back to the first page
      });
      setScrollAmount(0);
    }, timeoutPerPage);
  };

  const clearTimeoutsAndIntervals = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (scrollInterval.current) cancelAnimationFrame(scrollInterval.current);
    scrollInterval.current = null;
  };

  const scrollPage = (timeoutPerPage) => {
    clearTimeoutsAndIntervals();

    if (maxScroll <= 0 || timeoutPerPage <= 0) return;

    const frameDuration = 1000 / 60;
    const totalFrames = Math.ceil(timeoutPerPage / frameDuration);
    const pixelsPerScroll = maxScroll / totalFrames;

    const smoothScroll = () => {
      setScrollAmount((prev) => {
        const newScrollAmount = prev + pixelsPerScroll;
        if (newScrollAmount >= maxScroll) {
          cancelAnimationFrame(scrollInterval.current);
          return maxScroll;
        }
        return newScrollAmount;
      });

      scrollInterval.current = requestAnimationFrame(smoothScroll);
    };

    scrollInterval.current = requestAnimationFrame(smoothScroll);
  };

  useEffect(() => {
    if (notice?.data?.pdf_uri) {
      // Reset all states when the document changes
      setPDF(notice.data.pdf_uri);
      setPageNumber(1); // Always start from the first page
      setScrollAmount(0);
      setMaxScroll(0);
      setPageLoaded(false);
      setDocumentLoaded(false);
      clearTimeoutsAndIntervals();
    }
    setScrolling(notice?.data?.options?.scrolling === 'true');
  }, [notice]);

  useEffect(() => {
    if (!numPages || !pageLoaded || !documentLoaded || !containerRef.current)
      return;

    const timeoutPerPage = noticeDisplayTime / numPages;

    const handlePageTransition = () => {
      calculateMaxScroll();

      if (scrolling && maxScroll > 0) {
        scrollPage(timeoutPerPage);
      }

      startTimeout();
    };

    handlePageTransition();

    return () => {
      clearTimeoutsAndIntervals();
    };
  }, [
    numPages,
    pageLoaded,
    documentLoaded,
    pageNumber,
    scrolling,
    noticeDisplayTime,
    maxScroll,
  ]);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1); // Reset to the first page when the document loads
    setDocumentLoaded(true);
  };

  const handlePageLoadSuccess = (page) => {
    setPageLoaded(true); // Start only after the current page is fully loaded
    setScrollAmount(0);

    const { view, rotate } = page._pageInfo;
    let [width, height] = view.slice(2);
    if (rotate === 90 || rotate === 270) {
      [width, height] = [height, width];
    }

    setPageWidth(width);
    setPageHeight(height);
    requestAnimationFrame(calculateMaxScroll);
  };

  useEffect(() => {
    if (numPages && pageNumber > numPages) {
      setPageNumber(1);
    }
  }, [numPages, pageNumber]);

  return (
    <div
      ref={containerRef}
      className="h-full overflow-hidden relative flex items-center justify-center"
      style={{
        height: `${containerHeight}px`,
        width: `${containerWidth}px`,
        paddingTop: scrolling ? '100px' : '',
      }}
    >
      <div
        style={{
          transform:
            scrolling && pageLoaded ? `translateY(-${scrollAmount}px)` : '',
          transition: 'transform 0.05s linear',
        }}
      >
        <Document
          file={PDF}
          onLoadSuccess={handleDocumentLoadSuccess}
          loading={<PdfMsg msg="Loading..." />}
          error={<PdfMsg msg="Error loading document." />}
          noData={<PdfMsg msg="No PDF file to display." />}
        >
          {numPages > 0 && pageNumber <= numPages && (
            <Page
              style={{
                width: 'fit-content',
                height: 'fit-content',
                maxWidth: pageWidth,
                maxHeight: pageHeight,
                marginTop: scrolling ? '40px' : '',
              }}
              scale={scale}
              pageNumber={pageNumber}
              onLoadSuccess={handlePageLoadSuccess}
              onLoadError={(error) =>
                console.error(
                  'Error opening doc: ',
                  PDF,
                  ' On page: ',
                  pageNumber,
                  error
                )
              }
              loading={<PdfMsg msg="Loading page..." />}
              error={<PdfMsg msg={`Error loading page ${pageNumber}.`} />}
            />
          )}
        </Document>
      </div>
    </div>
  );
};

const PdfMsg = ({ msg }) => (
  <div className="p-2 text-lg">
    <p>{msg}</p>
  </div>
);

export default PdfNotice;
