import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import Ad from './Ad'

// AdPreloader is more like a filter for Ad element
const AdPreloader = ({ ads, ...props }) => {
  // Ads, that are loaded and ready to go screen
  const [loaded, setLoaded] = useState([])
  const queue = useRef([])

  // axios cancellation token incase component will unMount
  // Read more https://github.com/axios/axios#cancellation
  const cancel = useRef(axios.CancelToken.source())

  const loadByIndex = useCallback(
    (index, force = false) => {
      if (!queue.current[index]) return

      const ad = queue.current[index]

      if (ad.loading) return

      // If ad already loaded or loading skip
      if (!force && ad.loaded) {
        window.fetch(ad.media).then(res => {
          if (res.status === 200) {
            loadByIndex(index + 1, true)
          }
        })
        return
      }

      ad.loading = true
      ad.from = `${ad.media}`

      // axios instance with wanted configs
      const instance = axios.create({
        timeout: 60000,
        responseType: 'blob'
      })

      // Start loading ad media
      instance.get(ad.loaded ? ad.from : ad.media, {
        // Add cancellation token here
        cancelToken: cancel.current.token
      }).then(response => {
        // Success
        // Create blob link
        const blob = URL.createObjectURL(response.data)
        ad.media = blob // Set media to blob

        // Add some info for loaders
        ad.loaded = true
        ad.failed = false
        ad.loading = false
      }).catch(error => {
        // Error
        // Add some info for loaders
        ad.loaded = false
        ad.failed = true
        ad.loading = false
        console.log(error)
      }).then(() => {
        // Always
        queue.current = queue.current.map(item => {
          if (item.key === ad.key) return ad
          return item
        })

        setLoaded(queue.current.filter(item => item.loaded))

        // Go to next item
        loadByIndex(index + 1)
      })
    },
    [queue]
  )

  // Merge props.ads to queue
  // TODO: Same merge feature as in updateAds()
  const mergeToQueue = useCallback(
    () => {
      // console.log('merging')
      // Filter only ads that are left in new ads
      const relevants = queue.current.filter(item => ads.find(el => el.key === item.key))
      // console.log('relevants', relevants, ads)
      const novelties = ads.filter(item => !relevants.find(el => el.key === item.key))
      // console.log('novelties', novelties)
      // Merge only relevant and novelty ads
      // console.log('queue.current', [...relevants, ...novelties])
      queue.current = [...relevants, ...novelties]
      loadByIndex(0)
    },
    [ads, loadByIndex]
  )

  useEffect(() => {
    // console.log('mainokset muuttuu')
    mergeToQueue()
  }, [ads, mergeToQueue])

  // Cancel requests if unMount
  useEffect(() => {
    // Returns true/false if any failed loadings
    // const anyFailed = () => {
    //   const find = _.find(
    //     queue,
    //     (ad) => {
    //       return ad.failed && !ad.loading
    //     })
    //   return !_.isUndefined(find)
    // }
    // // Interval that check failed loads every 30s
    // const interval = window.setInterval(
    //   () => {
    //     if (anyFailed()) {
    //       loadAds()
    //     }
    //   }, 30000)
    const source = cancel.current
    return () => {
      source.cancel()
      // window.clearInterval(interval)
    }
  }, [cancel])

  return <Ad ads={loaded} />
}

AdPreloader.propTypes = {
  ads: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['image', 'video']).isRequired,
    media: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    repeats: PropTypes.number
  }))
}

AdPreloader.defaultProps = {
  ads: []
}

export default AdPreloader
