import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import Infobar from './CondoElements/Infobar';
import ResidentList from './CondoElements/Resident-list';
import PdfNotice from './CondoElements/PdfNotice';
import Notice from './CondoElements/notice';

const DEFAULT_DISPLAY_TIME = 45000; // In milliseconds

const Condominium = ({ news, residentList, infobar, ...props }) => {
  const [current, setCurrent] = useState(0);
  const [timeoutTime, setTimeoutTime] = useState(DEFAULT_DISPLAY_TIME); // In milliseconds
  const timeouts = useRef([]);
  const progress = useRef(false);
  const newId = useRef(null);
  /* ResidentList */
  const residentListRef = useRef(null);
  const [rlWidth, setRlWidth] = useState(0);

  const currentNew = useMemo(() => {
    if (news.length < 1) return false;
    if (!news[current]) {
      if (current !== 0) setCurrent(0);
      return false;
    }
    return {
      index: current,
      total_news: news.length,
      ...news[current],
    };
  }, [current, news]);

  const newTimeout = (callback, milliseconds) => {
    const timeout = window.setTimeout(callback, milliseconds);
    timeouts.current.push(timeout);
  };

  const clearTimeouts = () => {
    timeouts.current.forEach((timeout) => window.clearTimeout(timeout));
    timeouts.current = [];
  };

  useEffect(() => {
    return () => {
      clearTimeouts();
    };
  }, []);

  useEffect(() => {
    if (residentListRef.current) {
      setRlWidth(residentListRef.current.offsetWidth);
    }
  }, [residentList, currentNew]);

  // Estimate notice container height.
  // TopBar = 96px
  // date bar = 56px
  // infobar = 15rem = 240px
  // 10px for good measure
  let estimatedContainerHeight = window.innerHeight - 96 - 56 - 10;
  if (infobar && infobar.length > 0) {
    estimatedContainerHeight -= 240;
  }

  // 50% unless resident list is enabled
  let estimatedContainerWidth = residentList
    ? window.innerWidth * 0.5 - rlWidth
    : window.innerWidth * 0.5;

  const displayNew = useMemo(() => {
    clearTimeouts();

    if (!currentNew) {
      progress.current = false;
      return null;
    }

    if (
      currentNew.data !== null &&
      typeof currentNew.data !== 'undefined' &&
      'show_time' in currentNew.data &&
      currentNew.data.show_time
    ) {
      setTimeoutTime(() => Number(currentNew.data.show_time) * 1000);
    } else {
      setTimeoutTime(DEFAULT_DISPLAY_TIME);
    }

    progress.current = timeoutTime;

    newTimeout(() => {
      if (newId.current) newId.current.firstChild.scrollTop = 0;
      setCurrent((prevCurrent) => prevCurrent + 1);
    }, timeoutTime);

    if (!('type' in currentNew) || currentNew.type === 'text') {
      return (
        <div
          className="overflow-hidden text-justify p-9"
          style={{
            textWrap: 'wrap',
            overflowWrap: 'anywhere',
            maxHeight: estimatedContainerHeight,
          }}
        >
          <Notice
            notice={currentNew}
            noticeDisplayTime={timeoutTime}
            containerHeight={estimatedContainerHeight}
            containerWidth={estimatedContainerWidth}
          />
        </div>
      );
    }

    return (
      <div
        className="flex mt-1 text-justify"
        style={{
          width: estimatedContainerWidth,
          height: estimatedContainerHeight,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PdfNotice
          notice={currentNew}
          noticeDisplayTime={timeoutTime}
          containerHeight={estimatedContainerHeight}
          containerWidth={estimatedContainerWidth}
        />
      </div>
    );
  }, [currentNew, timeoutTime]);

  useEffect(() => {
    if (newId.current) {
      newId.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [currentNew]);

  return (
    <div className="flex flex-col h-full font-body flex-even">
      <div
        ref={newId}
        className="relative flex flex-grow flex-col"
        style={{ maxWidth: `${window.innerWidth * 0.5}px` }}
      >
        <div className="flex overflow-hidden h-full">
          <div>{displayNew}</div>
          {residentList && (
            <div
              ref={residentListRef}
              className="flex px-6 py-5 mr-0 ml-auto w-fit"
            >
              <ResidentList residentList={residentList} />
            </div>
          )}
        </div>
        <div className="flex-row bottom-0 left-0 flex justify-between w-full py-3 bg-white px-9 shadow-newinfo border-box bg-opacity-90">
          <p className="text-2xl font-extralight">
            {currentNew.index + 1} / {currentNew.total_news}
          </p>
          <p className="text-2xl font-light">
            {!_.isUndefined(currentNew.date) && currentNew.date}
          </p>
        </div>
        <span
          key={currentNew.index}
          style={{ animationDuration: `${progress.current}ms` }}
          className="absolute bottom-0 left-0 h-1.5 bg-gray-600 w-0 progressBar"
        />
      </div>

      {/* Bottom bar */}
      {infobar && infobar.length > 0 && (
        <Infobar items={infobar} progress={progress.current} />
      )}
    </div>
  );
};

Condominium.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.string),
      date: PropTypes.string,
    })
  ),
  infobar: PropTypes.array,
};

Condominium.defaultProps = {
  news: [],
  infobar: [],
};

export default Condominium;
