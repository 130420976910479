import React, { useState, useEffect } from "react";
import holidays from 'date-holidays';
import PropTypes from "prop-types";
import WeatherWidget from "./CondoElements/CondoWidgets/WeatherWidget";
import TimeWidget from "./CondoElements/CondoWidgets/TimeWidget";
import logo from "../../../images/logo.min.svg";

const TopBar = ({ label, location, ...props }) => {
  // Set widget visibility options
  const [options, setOptions] = useState({
    showLogo: false,
    showUrl: true,
    showTime: true,
    showWeather: false,
    showLocation: true,
    showHolidays: true,
  });

  const HolidaysWidget = () => {
    const [todayString, setTodayString] = useState("")
    const [fetchInterval, setFetchInterval] = useState(1800000); //updates every 30 minutes

    useEffect(() => {
      TodayString();
      CheckHolidayDate();
      setInterval(TodayString, fetchInterval);
      setInterval(CheckHolidayDate, fetchInterval);
    }, []);

    // Makes today's date into a string that CheckHolidayDate() can understand
    const TodayString = () => {
      const today = new Date()
      const date = today.getDate()
      const month = today.getMonth() + 1
      const year = today.getYear() + 1900

      setTodayString(`${year}-${month < 10 ? "0" : ""}${month}-${date < 10 ? "0" : ""}${date} 00:00:00`)
    }

    const hd = new holidays('FI')
    const holiday = hd.getHolidays()

    // Checks if theres any holidays today
    const CheckHolidayDate = () => {
      for (let i = 0; i < holiday.length; i++) {
        const fullHoliday = holiday[i].date

        if (todayString == fullHoliday) {
          if (holiday[i].name == '2. joulupäivä') { // Change the name to a correct one
            return (
              <div className="widget">
                <span>Tapaninpäivä</span>
              </div>
            )
          }
          else return (
            <div className="widget">
              <span>{holiday[i].name}</span>
            </div>
          )
        }
      }
    }
    return CheckHolidayDate()
  }

  return (
    <div className="font-body flex flex-grow items-center gap-6 px-6">
      {options.showUrl && <h2 className="font-regular text-2xl">skyled.fi</h2>}
      <div className="flex flex-grow gap-4 items-center">
        {options.showLogo && <img src={logo} alt="Opasti" className="h-8" />}
        {options.showTime && <TimeWidget />}
        {options.showWeather && <WeatherWidget />}
        {options.showHolidays && HolidaysWidget()}
      </div>

      {options.showLocation && location && <h1 className="font-regular text-2xl">{location}</h1>}
    </div>
  );
};

TopBar.propTypes = {
  location: PropTypes.string,
};

TopBar.defaultProps = {
  location: "",
};

export default TopBar;
