import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Columns from './InfobarContents/Columns';
import Text from './InfobarContents/Text';
import List from './InfobarContents/List';
import _ from 'underscore';

const Infobar = ({ items, ...props }) => {
  const last = useRef(0);
  const [current, setCurrent] = useState(false);
  const timeouts = useRef([]);

  const newTimeout = (callback, milliseconds) => {
    const timeout = window.setTimeout(callback, milliseconds);
    timeouts.current.push(timeout);
  };

  const clearTimeouts = () => {
    const removed = _.map(timeouts.current, (timeout) =>
      window.clearTimeout(timeout)
    );
    timeouts.current = _.difference(timeouts.current, removed);
  };

  useEffect(() => {
    const next = () => {
      if (items[last.current + 1]) {
        setCurrent(items[last.current + 1]);
        last.current = last.current + 1;
      } else {
        last.current = 0;
        setCurrent(items[0] || false);
      }

      newTimeout(() => {
        next();
      }, 20000);
    };

    next();
    return () => {
      clearTimeouts();
    };
  }, [items]);

  if (!current) return null;

  return (
    <div
      className="flex relative shadow-infobar px-4 py-4 bg-white w-full"
      style={{ height: '15rem' }}
    >
      <ul
        className={
          'flex flex-col h-full text-lg infobar-list mr-1' +
          (items.length > 3 ? ' justify-between' : ' justify-around')
        }
      >
        {items.map((item) => (
          <li
            key={item.id + '-' + item.title}
            className={
              'list-none text-left' + (item.id === current.id ? ' current' : '')
            }
          >
            {item.title}
          </li>
        ))}
      </ul>

      {current.item && current.item.type
        ? {
            columns: <Columns item={current} />,
            text: <Text item={current} />,
            list: <List item={current} />,
          }[current.item.type]
        : null}
    </div>
  );
};

Infobar.propTypes = {
  items: PropTypes.array,
};

Infobar.defaultProps = {
  items: [],
};

export default Infobar;
